import {
  Box,
  Card,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Link,
  List,
  ListItemButton,
  Paper,
  Popover,
  Popper,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import "./personalLog.css";
import React, { useEffect, useState } from "react";
import { PREDICT_TYPE } from "../../../../Util/define";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import Looks4Icon from "@mui/icons-material/Looks4";
import Looks5Icon from "@mui/icons-material/Looks5";
import { FormatDateFromStrConsider } from "../../../../Util/function";
import { userInfo } from "../../../../Util/recoil/atom";
import { useRecoilValue } from "recoil";
import {
  FindContextPredictsNext5Myself,
  FindPredictsNext5Myself,
} from "../../../../Util/web_api";

function PersonalLog(props) {
  const [value, setValue] = useState(0);
  const [page, setPage] = useState(0);
  const [predictType, setPredictType] = useState(PREDICT_TYPE.HIRAGANA_TREE);
  const [allLowCount, setAllRowCount] = useState(0);
  const [logs, setLogs] = useState([]);
  const [detailIndex, setDetailIndex] = useState(-1);

  // recoil
  const _userInfo = useRecoilValue(userInfo);

  useEffect(() => {
    if (
      predictType !== PREDICT_TYPE.SENTENCE_CNN &&
      predictType !== PREDICT_TYPE.SENTENCE_TREE
    ) {
      FindPredictsNext5Myself(
        _userInfo.userId,
        predictType,
        page,
        (res) => {
          setAllRowCount(res.data.allRowCount);
          setLogs((prev) => [...prev, ...res.data.logs]);
        },
        (e) => {
          console.warn(e);
        }
      );
    } else {
      FindContextPredictsNext5Myself(
        _userInfo.userId,
        predictType,
        page,
        (res) => {
          console.log(res);

          setAllRowCount(res.data.allRowCount);
          setLogs((prev) => [...prev, ...res.data.logs]);
        },
        (e) => {
          console.warn(e);
        }
      );
    }
  }, [predictType, page]);

  const handleChange = (event, newValue) => {
    const tmp = Number(newValue);
    setPredictType(tmp * 2);
    setValue(tmp);
    setPage(0);
    setLogs([]);
  };

  const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;
    console.log(index);

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  };

  const logViewer = () => {
    return (
      <Box>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={predictType % 2}
            onChange={(e) => {
              setPredictType(value * 2 + Number(e.target.value));
              setPage(0);
              setLogs([]);
            }}
          >
            <FormControlLabel
              value={0}
              control={<Radio />}
              label="決定木分類モデル"
            />
            <FormControlLabel
              value={1}
              control={<Radio />}
              label="CNN分類モデル"
            />
          </RadioGroup>
        </FormControl>
        <Paper elevation={3} sx={{ padding: "10px" }}>
          <Typography>認識ログ</Typography>
          <Divider />
          {logs.length !== 0 ? (
            <List sx={{ padding: 0 }}>
              {logs.map((l, i) => (
                <React.Fragment>
                  {i !== 0 && <Divider />}
                  <ListItemButton>
                    <Typography>
                      {FormatDateFromStrConsider(l.predictDate, 9)}
                    </Typography>
                    <img
                      src={l.image}
                      width={100}
                      height={100}
                      style={{ marginLeft: "10px" }}
                    />
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "10px",
                      }}
                    >
                      <LooksOneIcon
                        sx={{ marginRight: "5px", color: "#fbc02d" }}
                      />
                      {`${l.predict1}：${l.predict1Probability}%`}
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "10px",
                      }}
                    >
                      <LooksTwoIcon
                        sx={{ marginRight: "5px", color: "#3f51b5" }}
                      />
                      {`${l.predict2}：${l.predict2Probability}%`}
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "10px",
                      }}
                    >
                      <Looks3Icon
                        sx={{ marginRight: "5px", color: "#ff6d00" }}
                      />
                      {`${l.predict3}：${l.predict3Probability}%`}
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "10px",
                      }}
                    >
                      <Looks4Icon
                        sx={{ marginRight: "5px", color: "#9e9e9e" }}
                      />
                      {`${l.predict4}：${l.predict4Probability}%`}
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "10px",
                      }}
                    >
                      <Looks5Icon
                        sx={{ marginRight: "5px", color: "#9e9e9e" }}
                      />
                      {`${l.predict5}：${l.predict5Probability}%`}
                    </Typography>
                  </ListItemButton>
                </React.Fragment>
              ))}
            </List>
          ) : (
            <Typography sx={{ padding: "10px" }}>
              ログが見つかりませんでした。
            </Typography>
          )}
          <Divider />
          <Box sx={{ display: "flex" }}>
            <FormHelperText
              sx={{
                justifyContent: "flex-start",
              }}
            >
              {`全体${allLowCount}件のうち${logs.length}件を表示しています`}
            </FormHelperText>
            <div style={{ flexGrow: 1 }} />
            {allLowCount > logs.length && (
              <Link
                sx={{ cursor: "pointer" }}
                onClick={() => setPage(page + 1)}
              >
                さらに読み込む
              </Link>
            )}
          </Box>
        </Paper>
      </Box>
    );
  };

  const contextLogViewer = () => {
    if (
      predictType !== PREDICT_TYPE.SENTENCE_CNN &&
      predictType !== PREDICT_TYPE.SENTENCE_TREE
    ) {
      return;
    }
    // 予測した結果を「文字列(123等)」と「確率(平均値)」の配列で返します
    const calcContextProbability = (predicts) => {
      // 全ての桁の組み合わせを作成
      const combinations = predicts.reduce(
        (acc, curr) =>
          acc.flatMap((combination) =>
            curr.map((item) => ({
              digits: [...combination.digits, item.digit],
              probability: combination.probability + item.probability,
            }))
          ),
        [{ digits: [], probability: 0 }]
      );

      // 確率の降順でソートし、上位5件を取得
      return combinations
        .sort((a, b) => b.probability - a.probability)
        .slice(0, 5);
    };

    // 予測した結果を「文字列(123等)」と「確率(平均値)」の配列で返します
    const calcContextProbabilityFromLog = (predicts) => {
      const allPredictions = predicts.map((p) => [
        { digit: p.predict1, probability: p.predict1Probability },
        { digit: p.predict2, probability: p.predict2Probability },
        { digit: p.predict3, probability: p.predict3Probability },
        { digit: p.predict4, probability: p.predict4Probability },
        { digit: p.predict5, probability: p.predict5Probability },
      ]);
      console.log(calcContextProbability(allPredictions));
      return calcContextProbability(allPredictions);
    };

    return (
      <Box sx={{ display: "sticky" }}>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={predictType % 2}
            onChange={(e) => {
              setPredictType(value * 2 + Number(e.target.value));
              setPage(0);
              setLogs([]);
            }}
          >
            <FormControlLabel
              value={0}
              control={<Radio />}
              label="決定木分類モデル"
            />
            <FormControlLabel
              value={1}
              control={<Radio />}
              label="CNN分類モデル"
            />
          </RadioGroup>
        </FormControl>
        <Paper elevation={3} sx={{ padding: "10px" }}>
          <Typography>認識ログ</Typography>
          <Divider />
          {logs.length !== 0 ? (
            <List sx={{ padding: 0 }}>
              {logs.map((l, i) => {
                const pl = calcContextProbabilityFromLog(l.children);
                return (
                  <React.Fragment>
                    {i !== 0 && <Divider />}
                    <ListItemButton>
                      <Typography>
                        {FormatDateFromStrConsider(l.predictDate, 9)}
                      </Typography>
                      <img
                        src={l.image}
                        width={199}
                        height={100}
                        style={{ marginLeft: "10px" }}
                      />
                      <Box>
                        <Box sx={{ display: "flex" }}>
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "10px",
                            }}
                          >
                            <LooksOneIcon
                              sx={{ marginRight: "5px", color: "#fbc02d" }}
                            />
                            {`${pl[0].digits.join("")}：${(
                              pl[0].probability / pl[0].digits.length
                            ).toFixed(3)}%`}
                          </Typography>
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "10px",
                            }}
                          >
                            <LooksTwoIcon
                              sx={{ marginRight: "5px", color: "#3f51b5" }}
                            />
                            {`${pl[1].digits.join("")}：${(
                              pl[1].probability / pl[1].digits.length
                            ).toFixed(3)}%`}
                          </Typography>
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "10px",
                            }}
                          >
                            <Looks3Icon
                              sx={{ marginRight: "5px", color: "#ff6d00" }}
                            />
                            {`${pl[2].digits.join("")}：${(
                              pl[2].probability / pl[2].digits.length
                            ).toFixed(3)}%`}
                          </Typography>
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "10px",
                            }}
                          >
                            <Looks4Icon
                              sx={{ marginRight: "5px", color: "#9e9e9e" }}
                            />
                            {`${pl[3].digits.join("")}：${(
                              pl[3].probability / pl[3].digits.length
                            ).toFixed(3)}%`}
                          </Typography>
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "10px",
                            }}
                          >
                            <Looks5Icon
                              sx={{ marginRight: "5px", color: "#9e9e9e" }}
                            />
                            {`${pl[4].digits.join("")}：${(
                              pl[4].probability / pl[4].digits.length
                            ).toFixed(3)}%`}
                          </Typography>
                        </Box>
                        {l.children.length > 0 && (
                          <React.Fragment>
                            <Link
                              onClick={() =>
                                detailIndex === i
                                  ? setDetailIndex(-1)
                                  : setDetailIndex(i)
                              }
                              sx={{
                                marginTop: "10px",
                                marginLeft: "10px",
                                cursor: "pointer",
                              }}
                            >
                              {detailIndex === i
                                ? "各文字の認識結果を非表示にする"
                                : "各文字の認識結果を表示する"}
                            </Link>
                            <Collapse in={detailIndex === i}>
                              <Card
                                variant="outlined"
                                sx={{
                                  padding: "10px",
                                  marginLeft: "10px",
                                  backgroundColor: "transparent",
                                }}
                              >
                                {l.children.map((c, i) => (
                                  <React.Fragment>
                                    {i !== 0 && (
                                      <Divider
                                        sx={{
                                          marginTop: "5px",
                                          marginBottom: "5px",
                                        }}
                                      />
                                    )}
                                    <Box
                                      sx={{
                                        marginLeft: "10px",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img src={c.image} height={100} />
                                        <Box>
                                          <Typography>{`${
                                            i + 1
                                          }文字目`}</Typography>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <LooksOneIcon
                                                sx={{
                                                  marginRight: "5px",
                                                  color: "#fbc02d",
                                                }}
                                              />
                                              {`${c.predict1}：${c.predict1Probability}%`}
                                            </Typography>
                                            <Typography
                                              sx={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <LooksTwoIcon
                                                sx={{
                                                  marginRight: "5px",
                                                  color: "#3f51b5",
                                                }}
                                              />
                                              {`${c.predict2}：${c.predict2Probability}%`}
                                            </Typography>
                                            <Typography
                                              sx={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <Looks3Icon
                                                sx={{
                                                  marginRight: "5px",
                                                  color: "#ff6d00",
                                                }}
                                              />
                                              {`${c.predict3}：${c.predict3Probability}%`}
                                            </Typography>
                                            <Typography
                                              sx={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <Looks4Icon
                                                sx={{
                                                  marginRight: "5px",
                                                  color: "#9e9e9e",
                                                }}
                                              />
                                              {`${c.predict4}：${c.predict4Probability}%`}
                                            </Typography>
                                            <Typography
                                              sx={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <Looks5Icon
                                                sx={{
                                                  marginRight: "5px",
                                                  color: "#9e9e9e",
                                                }}
                                              />
                                              {`${c.predict5}：${c.predict5Probability}%`}
                                            </Typography>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </React.Fragment>
                                ))}
                              </Card>
                            </Collapse>
                          </React.Fragment>
                        )}
                      </Box>
                    </ListItemButton>
                  </React.Fragment>
                );
              })}
            </List>
          ) : (
            <Typography sx={{ padding: "10px" }}>
              ログが見つかりませんでした。
            </Typography>
          )}
          <Divider />
          <Box sx={{ display: "flex" }}>
            <FormHelperText
              sx={{
                justifyContent: "flex-start",
              }}
            >
              {`全体${allLowCount}件のうち${logs.length}件を表示しています`}
            </FormHelperText>
            <div style={{ flexGrow: 1 }} />
            {allLowCount > logs.length && (
              <Link
                sx={{ cursor: "pointer" }}
                onClick={() => setPage(page + 1)}
              >
                さらに読み込む
              </Link>
            )}
          </Box>
        </Paper>
      </Box>
    );
  };

  return (
    <div className="pcPersonalLog">
      <Typography>認識ログ(個人)</Typography>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label="ひらがな"
              sx={{ backgroundColor: value === 0 ? "#e3f2fd" : "white" }}
            />
            <Tab
              label="数字"
              sx={{ backgroundColor: value === 1 ? "#e3f2fd" : "white" }}
            />
            <Tab
              label="複数数字"
              sx={{ backgroundColor: value === 2 ? "#e3f2fd" : "white" }}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          {logViewer()}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          {logViewer()}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          {contextLogViewer()}
        </CustomTabPanel>
      </Box>
    </div>
  );
}

export default PersonalLog;
