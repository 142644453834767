import React, { useEffect } from "react";
import axios from "axios";
import { BASE_ADDRESS, PREDICT_ADDRESS } from "./define";
import { useSetRecoilState } from "recoil";
import { loading } from "./recoil/atom";

// api実行に関するファイル
// 成功、失敗時の処理はコールバック関数としてsuccess, failureに記述

// ユーザーを登録
export const RegistUser = (poster, success, failure) => {
  axios
    .post(`${BASE_ADDRESS}Users`, poster)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// ユーザーを取得
export const FindUserByUserId = (userId, success, failure) => {
  axios
    .get(`${BASE_ADDRESS}Users/?userId=${userId}`)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// ユーザーを変更
export const UpdateUser = (poster, success, failure) => {
  axios
    .put(`${BASE_ADDRESS}Users`, poster)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 予測を登録
export const RegistPredict = (poster, success, failure) => {
  axios
    .post(`${BASE_ADDRESS}Predicts`, poster)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 自身の上位3件の予測を取得
export const FindPredictsTop3Myself = (userId, type, success, failure) => {
  axios
    .get(`${BASE_ADDRESS}Predicts/?userId=${userId}&type=${type}`)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 自身の次の5件の予測を取得
export const FindPredictsNext5Myself = (
  userId,
  type,
  page,
  success,
  failure
) => {
  axios
    .get(
      `${BASE_ADDRESS}Predicts/Next5Myself/?userId=${userId}&type=${type}&page=${page}`
    )
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 次の5件の予測を取得
export const FindPredictsNext5 = (type, page, success, failure) => {
  axios
    .get(`${BASE_ADDRESS}Predicts/Next5/?type=${type}&page=${page}`)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// Context予測を登録
export const RegistContextPredict = (poster, success, failure) => {
  axios
    .post(`${BASE_ADDRESS}Predicts/context`, poster)
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// Context自身の上位3件の予測を取得
export const FindContextPredictsTop3Myself = (
  userId,
  type,
  success,
  failure
) => {
  axios
    .get(
      `${BASE_ADDRESS}Predicts/contextPredictTop3/?userId=${userId}&type=${type}`
    )
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// Context自身の次の5件の予測を取得
export const FindContextPredictsNext5Myself = (
  userId,
  type,
  page,
  success,
  failure
) => {
  axios
    .get(
      `${BASE_ADDRESS}Predicts/contextPredictNext5Myself/?userId=${userId}&type=${type}&page=${page}`
    )
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// Context次の5件の予測を取得
export const FindContextPredictsNext5 = (type, page, success, failure) => {
  axios
    .get(
      `${BASE_ADDRESS}Predicts/contextPredictNext5/?type=${type}&page=${page}`
    )
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// ひらがな予測APIを送信
export const PredictHiraganaByCNNModel = (base64, success, failure) => {
  axios
    .post(`${PREDICT_ADDRESS}predict/hiragana_cnn`, { image: base64 })
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// ひらがな予測APIを送信
export const PredictHiraganaByTreeModel = (base64, success, failure) => {
  axios
    .post(`${PREDICT_ADDRESS}predict/hiragana_tree`, { image: base64 })
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 数値予測APIを送信
export const PredictNumByCNNModel = (base64, success, failure) => {
  axios
    .post(`${PREDICT_ADDRESS}predict/num_cnn`, { image: base64 })
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// 複数の数値予測APIを送信
export const PredictNumsByCNNModel = (base64, success, failure) => {
  axios
    .post(`${PREDICT_ADDRESS}predict/nums_cnn`, { image: base64 })
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      failure(e);
    });
};

// インターセプター
// requestで通信前共通処理
// responseで通信後共通処理
export const AxiosClientProvider = ({ children }) => {
  const setIsLoading = useSetRecoilState(loading);

  useEffect(() => {
    // リクエスト インターセプター
    const requestInterceptors = axios.interceptors.request.use(
      function (config) {
        if (
          config.url === process.env.REACT_APP_OPENAI_API_ENDPOINT ||
          config.url === process.env.REACT_APP_WEATHER_API_ENDPOINT ||
          config.url === process.env.REACT_APP_OPENCAGE_API_ENDPOINT
        ) {
          return config;
        }
        setIsLoading(true);
        return config;
      },
      function (err) {
        return Promise.reject(err);
      }
    );

    // レスポンス インターセプター
    const responseInterceptor = axios.interceptors.response.use(
      function (responce) {
        if (
          responce.config.url === process.env.REACT_APP_OPENAI_API_ENDPOINT ||
          responce.config.url === process.env.REACT_APP_WEATHER_API_ENDPOINT ||
          responce.config.url === process.env.REACT_APP_OPENCAGE_API_ENDPOINT
        ) {
          return responce;
        }
        setIsLoading(false);
        return responce;
      },
      function (err) {
        setIsLoading(false);
        return Promise.reject(err);
      }
    );

    // クリーンアップ
    return () => {
      axios.interceptors.request.eject(requestInterceptors);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);
  return <React.Fragment>{children}</React.Fragment>;
};
