import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Paper,
  Typography,
} from "@mui/material";
import "./header.css";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import { useLocation, useNavigate } from "react-router-dom";
import { PAGE_ID } from "../../../Util/define";
import logo from "./../../../image/logo.PNG";
import { userInfo, toastState } from "../../../Util/recoil/atom";
import { useSetRecoilState } from "recoil";
import WebIcon from "@mui/icons-material/Web";
import ApiIcon from "@mui/icons-material/Api";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import { APIClient } from "openai/core.mjs";
import { useState } from "react";
import ControlDialog from "../../../Util/commonComponent/controlDialog";
import uiLogo from "../../../image/ui.png";
import apiLogo from "../../../image/apiLogo.png";
import aiLogo from "../../../image/aiLogo.png";

function Header() {
  const [isOpenFrontDialog, setIsOpenFrontDialog] = useState(false);
  const [isOpenBackDialog, setIsOpenBackDialog] = useState(false);
  const [isOpenAIDialog, setIsOpenAIDialog] = useState(false);

  // recoil
  const _setUserInfo = useSetRecoilState(userInfo);
  const _setToastState = useSetRecoilState(toastState);

  const navigate = useNavigate();

  return (
    <div className="pcHeader">
      <ControlDialog
        isOpen={isOpenFrontDialog}
        doYes={() => setIsOpenFrontDialog(false)}
        yesText={"閉じる"}
        title={"フロントエンド"}
        content={
          <Box>
            <CardActionArea>
              <CardMedia component="img" image={uiLogo} alt="green iguana" />
              <CardContent>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  画面の構造を作るために <strong>HTML</strong>{" "}
                  <strong>CSS</strong> <strong>JavaScript</strong>{" "}
                  <strong>TypeScript</strong> を使用しています。
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary", marginTop: "8px" }}
                >
                  ユーザーインターフェースを効率的に構築するために{" "}
                  <strong>React</strong> というライブラリを使用し、 さらに{" "}
                  <strong>Material-UI (MUI)</strong>{" "}
                  を使って、洗練されたデザインのコンポーネントを
                  簡単に組み合わせられるようにしています。
                  これにより、モダンで機能的なフロントエンドを実現しています。
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary", marginTop: "8px" }}
                >
                  また、開発環境やビルドツールとして <strong>Node.js</strong>{" "}
                  を利用し、
                  サーバーサイドとの連携やアプリケーション全体の効率を高めています。
                </Typography>
              </CardContent>
            </CardActionArea>
          </Box>
        }
      />
      <ControlDialog
        isOpen={isOpenBackDialog}
        doYes={() => setIsOpenBackDialog(false)}
        yesText={"閉じる"}
        title={"バッグエンド・API"}
        content={
          <Box>
            <CardActionArea>
              <CardMedia component="img" image={apiLogo} alt="green iguana" />
              <CardContent>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  <strong>C#</strong> や <strong>Python</strong>
                  という言語を使用し、APIやバックエンドの機能を実装しています。
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary", marginTop: "8px" }}
                >
                  予測を行う処理は <strong>Flask</strong>{" "}
                  を使用し、バッグエンドでPythonコードを実行しています。
                  <br />
                  データベースとのやり取りには{" "}
                  <strong>Entity Framework Core (EF Core)</strong>{" "}
                  を使い、データは <strong>SQL Server</strong>{" "}
                  に保存されております。
                  <br />
                  バックグラウンドで動く定期的な作業を <strong>
                    Hangfire
                  </strong>{" "}
                  というツールを使って行っています。
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary", marginTop: "8px" }}
                >
                  システムは <strong>Windows Server</strong>{" "}
                  上にデプロイすることで、クラウド型Webアプリケーションとして動作しています。
                </Typography>
              </CardContent>
            </CardActionArea>
          </Box>
        }
      />
      <ControlDialog
        isOpen={isOpenAIDialog}
        doYes={() => setIsOpenAIDialog(false)}
        yesText={"閉じる"}
        title={"AI・機械学習"}
        content={
          <Box>
            <CardActionArea>
              <CardMedia component="img" image={aiLogo} alt="green iguana" />
              <CardContent>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  <strong>Python</strong>{" "}
                  という言語を使用しAI・機械学習機能を実装しています。
                  <br />
                  開発環境としては、<strong>Anaconda</strong>{" "}
                  で仮想環境を作成し、
                  <strong>Jupyter Notebook</strong>{" "}
                  を使用してコーディングを行いました。。
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary", marginTop: "8px" }}
                >
                  <strong>NumPy</strong>{" "}
                  を使用し、画像をデータとして扱っています。
                  <br />
                  機械学習には <strong>scikit-learn (sklearn)</strong>{" "}
                  を使用し、ランダムフォレストモデルを作成しています。
                  <br />
                  より精度の高い予測を行うために、<strong>
                    TensorFlow
                  </strong> や <strong>Keras</strong>{" "}
                  といったツールを使用して、CNNというモデルを作成しています。
                  <br />
                  機械学習の進捗を可視化するのに
                  <strong> matplotlib</strong> を使用し、
                  作成したモデルを保存して再利用するために、
                  <strong>joblib</strong> <strong>TensorFlow</strong>{" "}
                  を使用しています。
                </Typography>
              </CardContent>
            </CardActionArea>
          </Box>
        }
      />
      <div
        style={{
          display: "inline-block",
          width: "30%",
          textAlign: "left",
          marginTop: "3px",
        }}
      >
        <img src={logo} height="60px" />
      </div>

      <div
        style={{
          display: "inline-block",
          width: "70%",
          textAlign: "right",
        }}
      >
        <ButtonGroup
          size="large"
          variant="text"
          aria-label="Loading button group"
          sx={{ marginRight: "15px" }}
        >
          <Button
            onClick={() => setIsOpenFrontDialog(true)}
            startIcon={<WebIcon />}
          >
            フロントエンド
          </Button>
          <Button
            onClick={() => setIsOpenBackDialog(true)}
            startIcon={<ApiIcon />}
          >
            バッグエンド・API
          </Button>
          <Button
            onClick={() => setIsOpenAIDialog(true)}
            startIcon={<PsychologyAltIcon />}
          >
            AI・機械学習
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
}

export default Header;
