import { PREDICT_TYPE } from "../../../../Util/define";
import CharAI from "../../../Control/showAI/charAI";
import ContextAI from "../../../Control/showContextAI/contextAI";
import "./numCNN.css";

function NumsCNN(props) {
  return (
    <ContextAI
      viewBreadcrumb={"複数数字認識 - CNNモデル"}
      modelName={"CNN"}
      overview={`CNN分類モデルを使用した複数の数字認識AIモデルです。
        単一の数字認識モデルはテストデータに対し、97.98%と良好な精度を示しました。
        しかしながら、数字の大きさによって予測精度にばらつきがみられます。`}
      kindName={"数字"}
      predictType={PREDICT_TYPE.SENTENCE_CNN}
      keyname={"digit"}
    />
  );
}

export default NumsCNN;
