export const TimeToUTC = (date) => {
  if (!(date instanceof Date)) {
    throw new TypeError('Input should be a Date object');
  }
  // 9時間足す
  const utcDate = new Date(date.getTime() + 9 * 60 * 60 * 1000);
  return utcDate.toISOString();
};

export const FormatDateFromStrConsider = (
  strDate,
  consider,
  option,
  onlyTime
) => {
  if (strDate === null) {
    return;
  }
  const date = new Date(strDate);

  date.setHours(date.getHours() - consider);

  if (onlyTime) {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${hours}:${minutes}`;
  }

  if (option) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 月は0から始まるので+1します
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}/${month}/${day}`;
  } else {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 月は0から始まるので+1します
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}/${month}/${day} ${hours}:${minutes}`;
  }
};
