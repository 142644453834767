import {
  Box,
  Button,
  Card,
  Divider,
  FormHelperText,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import "./contextAI.css";
import React, { useEffect, useRef, useState } from "react";
import ManageSearchOutlinedIcon from "@mui/icons-material/ManageSearchOutlined";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import Looks4Icon from "@mui/icons-material/Looks4";
import Looks5Icon from "@mui/icons-material/Looks5";
import tmpImg from "../../../image/tmp.png";
import {
  FindContextPredictsTop3Myself,
  FindPredictsTop3Myself,
  PredictNumsByCNNModel,
  RegistContextPredict,
} from "../../../Util/web_api";
import { saveAs } from "file-saver";
import { useRecoilValue } from "recoil";
import { userInfo } from "../../../Util/recoil/atom";
import { FormatDateFromStrConsider, TimeToUTC } from "../../../Util/function";
import { PREDICT_ADDRESS, PREDICT_TYPE } from "../../../Util/define";
import ControlDialog from "../../../Util/commonComponent/controlDialog";

function ContextAI(props) {
  const {
    viewBreadcrumb,
    modelName,
    overview,
    kindName,
    predictType,
    keyname,
  } = props;
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [context, setContext] = useState(null);
  const [predicts, setPredicts] = useState([]);
  const [logs, setLogs] = useState([]);
  const [images, setImages] = useState([]);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isWrite, setIsWrite] = useState(false);
  // recoil
  const _userInfo = useRecoilValue(userInfo);

  useEffect(() => {
    findPredict();
    // Canvasの初期設定
    const canvas = canvasRef.current;
    canvas.width = 700; // キャンバスの幅
    canvas.height = 360; // キャンバスの高さ
    const ctx = canvas.getContext("2d");
    ctx.lineWidth = 25; // 線の太さ
    ctx.lineCap = "round"; // 線の端を丸くする
    ctx.strokeStyle = "black"; // 線の色
    ctx.fillStyle = "white"; // 背景色を白に設定
    ctx.fillRect(0, 0, canvas.width, canvas.height); // 背景を塗りつぶす
    setContext(ctx);
  }, []);

  const registPredicts = (predicts, images, image) => {
    const childrenPoster = predicts.map((p, i) => {
      return {
        Index: i,
        Image: images[i],
        Predict1: p[0][keyname],
        Predict1Probability: p[0].probability,
        Predict2: p[1][keyname],
        Predict2Probability: p[1].probability,
        Predict3: p[2][keyname],
        Predict3Probability: p[2].probability,
        Predict4: p[3][keyname],
        Predict4Probability: p[3].probability,
        Predict5: p[4][keyname],
        Predict5Probability: p[4].probability,
      };
    });
    const poster = {
      UserRelationId: _userInfo.userId,
      PredictType: predictType,
      PredictDate: TimeToUTC(new Date()),
      Image: image,
      Children: childrenPoster,
    };

    RegistContextPredict(
      poster,
      (res) => {
        setLogs((prevData) => {
          // 先頭に新しいデータを追加し、末尾を削除
          if (logs.length === 3) {
            const updatedData = [
              res.data,
              ...prevData.slice(0, prevData.length - 1),
            ];
            return updatedData;
          } else {
            const updatedData = [res.data, ...prevData];
            return updatedData;
          }
        });
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  const findPredict = () => {
    FindContextPredictsTop3Myself(
      _userInfo.userId,
      predictType,
      (res) => {
        setLogs(res.data);
      },
      (e) => {
        console.warn(e);
      }
    );
  };

  // 予測した結果を「文字列(123等)」と「確率(平均値)」の配列で返します
  const calcContextProbability = (predicts) => {
    // 全ての桁の組み合わせを作成
    const combinations = predicts.reduce(
      (acc, curr) =>
        acc.flatMap((combination) =>
          curr.map((item) => ({
            digits: [...combination.digits, item.digit],
            probability: combination.probability + item.probability,
          }))
        ),
      [{ digits: [], probability: 0 }]
    );

    // 確率の降順でソートし、上位5件を取得
    return combinations
      .sort((a, b) => b.probability - a.probability)
      .slice(0, 5);
  };

  // 予測した結果を「文字列(123等)」と「確率(平均値)」の配列で返します
  const calcContextProbabilityFromLog = (predicts) => {
    const allPredictions = predicts.map((p) => [
      { digit: p.predict1, probability: p.predict1Probability },
      { digit: p.predict2, probability: p.predict2Probability },
      { digit: p.predict3, probability: p.predict3Probability },
      { digit: p.predict4, probability: p.predict4Probability },
      { digit: p.predict5, probability: p.predict5Probability },
    ]);
    console.log(calcContextProbability(allPredictions));
    return calcContextProbability(allPredictions);
  };

  const startDrawing = (event) => {
    const { offsetX, offsetY } = event.nativeEvent;
    context.beginPath(); // パスを開始
    context.moveTo(offsetX, offsetY); // パスの開始点
    setIsDrawing(true);
  };

  const draw = (event) => {
    if (!isDrawing) return;
    if (!isWrite) {
      setIsWrite(true);
    }
    const { offsetX, offsetY } = event.nativeEvent;
    context.lineTo(offsetX, offsetY); // パスを引く
    context.stroke(); // パスを描画
  };

  const stopDrawing = () => {
    context.closePath(); // パスを閉じる
    setIsDrawing(false);
  };

  const clearCanvas = () => {
    context.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height); // 背景を塗りつぶす
    setIsWrite(false);
  };

  const exportToBase64 = () => {
    if (!isWrite) {
      setIsOpenDialog(true);
      return;
    }
    const canvas = canvasRef.current;
    const dataURL = canvas.toDataURL("image/png"); // Base64形式の画像データを取得
    switch (predictType) {
      case PREDICT_TYPE.SENTENCE_CNN:
        PredictNumsByCNNModel(
          dataURL,
          (res) => {
            setPredicts(res.data.predicts);
            setImages(res.data.images);
            calcContextProbability(res.data.predicts);
            registPredicts(res.data.predicts, res.data.images, dataURL);
          },
          (e) => {
            console.warn(e);
          }
        );
        break;
      default:
        break;
    }
  };

  return (
    <div className="pcCharAI">
      <ControlDialog
        isOpen={isOpenDialog}
        doYes={() => setIsOpenDialog(false)}
        yesText={"OK"}
        title={"無描画のためエラー"}
        content={
          <Typography>キャンパスに{kindName}を書いてください</Typography>
        }
      />
      <Typography>{viewBreadcrumb}</Typography>
      <Card variant="outlined" sx={{ padding: "10px" }}>
        <Typography>{"モデル概要"}</Typography>
        <Typography sx={{ marginLeft: "3px", marginTop: "3px" }}>
          {overview}
        </Typography>
        <Link>{`${modelName}モデルとは？`}</Link>
      </Card>
      <Box sx={{ marginTop: "20px", marginBottom: "10px" }}>
        <Typography>{`キャンパスに${kindName}を書き、認識ボタン押してください。`}</Typography>
      </Box>
      <Paper elevation={3} sx={{ padding: "10px", display: "flex" }}>
        <Box
          sx={{
            width: "740px",
            marginRight: "5px",
            borderRight: "1px solid #bdbdbd",
          }}
        >
          <Card
            variant="outlined"
            sx={{ padding: "10px", width: "700px", height: "360px" }}
          >
            <canvas
              ref={canvasRef}
              style={{
                cursor: "crosshair",
              }}
              onMouseDown={startDrawing}
              onMouseMove={draw}
              onMouseUp={stopDrawing}
              onMouseLeave={stopDrawing}
            />
          </Card>

          <Button
            variant="contained"
            sx={{ marginTop: "5px" }}
            startIcon={<ManageSearchOutlinedIcon />}
            onClick={exportToBase64}
          >
            {kindName}を認識
          </Button>
          <Button
            variant="outlined"
            sx={{ marginTop: "5px", marginLeft: "10px" }}
            startIcon={<RestartAltIcon />}
            onClick={clearCanvas}
          >
            キャンパスを白紙にする
          </Button>
        </Box>
        <Box
          sx={{
            width: "500px",
            paddingRight: "18px",
            marginLeft: "13px",
            paddingTop: "5px",
          }}
        >
          <Typography sx={{ marginBottom: "5px" }}>認識結果</Typography>
          {predicts.length != 0 ? (
            <React.Fragment>
              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "220px" }}>
                  <Divider />
                  <List sx={{ padding: 0 }}>
                    {calcContextProbability(predicts).map((p, i) => (
                      <React.Fragment>
                        <ListItemButton>
                          <ListItemIcon>
                            {i === 0 ? (
                              <LooksOneIcon sx={{ color: "#fbc02d" }} />
                            ) : i === 1 ? (
                              <LooksTwoIcon sx={{ color: "#3f51b5" }} />
                            ) : i === 2 ? (
                              <Looks3Icon sx={{ color: "#ff6d00" }} />
                            ) : i === 3 ? (
                              <Looks4Icon />
                            ) : (
                              <Looks5Icon />
                            )}
                          </ListItemIcon>
                          <ListItemText
                            primary={`${p.digits.join("")}：${(
                              p.probability / predicts.length
                            ).toFixed(3)}%`}
                          />
                        </ListItemButton>
                        <Divider />
                      </React.Fragment>
                    ))}
                  </List>
                  <FormHelperText>上位5件を表示しています</FormHelperText>
                </Box>
                <Card
                  variant="outlined"
                  sx={{
                    padding: "10px",
                    marginLeft: "10px",
                    width: "240px",
                    height: "240px",
                    overflowY: "scroll",
                  }}
                >
                  <List sx={{ padding: 0 }}>
                    {predicts.map((p, i) => (
                      <React.Fragment>
                        {i !== 0 && <Divider />}
                        <ListItemButton>
                          <img src={images[i]} width={70} height={70} />
                          <Box sx={{ marginLeft: "10px" }}>
                            <Typography>{`${i + 1}文字目`}</Typography>
                            <Box>
                              <Typography
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <LooksOneIcon
                                  sx={{ marginRight: "5px", color: "#fbc02d" }}
                                />
                                {`${p[0].digit}：${p[0].probability.toFixed(
                                  2
                                )}%`}
                              </Typography>
                              <Typography
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <LooksTwoIcon
                                  sx={{ marginRight: "5px", color: "#3f51b5" }}
                                />
                                {`${p[1].digit}：${p[1].probability.toFixed(
                                  2
                                )}%`}
                              </Typography>
                              <Typography
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Looks3Icon
                                  sx={{ marginRight: "5px", color: "#ff6d00" }}
                                />
                                {`${p[2].digit}：${p[2].probability.toFixed(
                                  2
                                )}%`}
                              </Typography>
                            </Box>
                          </Box>
                        </ListItemButton>
                      </React.Fragment>
                    ))}
                  </List>
                </Card>
              </Box>

              <Card
                variant="outlined"
                sx={{ padding: "10px", marginTop: "10px", height: "70px" }}
              >
                <Typography
                  variant="body1"
                  sx={{ color: "#03a9f4", marginBottom: "2px" }}
                >
                  AIによる認識結果評価
                </Typography>
                <Typography variant="body2">要約を表示します</Typography>
              </Card>
            </React.Fragment>
          ) : (
            <Box>結果が表示されます</Box>
          )}
        </Box>
      </Paper>
      <Paper elevation={3} sx={{ marginTop: "10px" }}>
        <Box
          sx={{
            paddingRight: "18px",
            marginLeft: "13px",
            paddingTop: "10px",
          }}
        >
          <Typography sx={{ marginBottom: "5px" }}>認識ログ</Typography>
          {logs.length != 0 ? (
            <List sx={{ padding: 0 }}>
              {logs.map((l, i) => {
                const p = calcContextProbabilityFromLog(l.children);
                return (
                  <React.Fragment>
                    {i === 0 && <Divider />}
                    <ListItemButton>
                      <img src={l.image} width={199} height={100} />
                      <Box sx={{ marginLeft: "10px" }}>
                        <Typography>
                          {FormatDateFromStrConsider(l.predictDate, 9)}
                        </Typography>
                        <Box>
                          <Typography
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <LooksOneIcon
                              sx={{ marginRight: "5px", color: "#fbc02d" }}
                            />
                            {`${p[0].digits.join("")}：${(
                              p[0].probability / p[0].digits.length
                            ).toFixed(3)}%`}
                          </Typography>
                          <Typography
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <LooksTwoIcon
                              sx={{ marginRight: "5px", color: "#3f51b5" }}
                            />
                            {`${p[1].digits.join("")}：${(
                              p[1].probability / p[1].digits.length
                            ).toFixed(3)}%`}
                          </Typography>
                          <Typography
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <Looks3Icon
                              sx={{ marginRight: "5px", color: "#ff6d00" }}
                            />
                            {`${p[2].digits.join("")}：${(
                              p[2].probability / p[2].digits.length
                            ).toFixed(3)}%`}
                          </Typography>
                        </Box>
                      </Box>
                      {l.children.length > 0 && (
                        <Card
                          variant="outlined"
                          sx={{
                            padding: "10px",
                            marginLeft: "10px",
                            display: "flex",
                            backgroundColor: "transparent",
                          }}
                        >
                          {l.children.map((c, i) => {
                            if (i > 2) {
                              return;
                            }
                            return (
                              <Box
                                sx={{ marginLeft: i === 0 ? "0px" : "10px" }}
                              >
                                <Typography>{`${i + 1}文字目`}</Typography>
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <img src={c.image} height={100} />
                                  <Box>
                                    <Typography
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <LooksOneIcon
                                        sx={{
                                          marginRight: "5px",
                                          color: "#fbc02d",
                                        }}
                                      />
                                      {`${c.predict1}：${c.predict1Probability}%`}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <LooksTwoIcon
                                        sx={{
                                          marginRight: "5px",
                                          color: "#3f51b5",
                                        }}
                                      />
                                      {`${c.predict2}：${c.predict2Probability}%`}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Looks3Icon
                                        sx={{
                                          marginRight: "5px",
                                          color: "#ff6d00",
                                        }}
                                      />
                                      {`${c.predict3}：${c.predict3Probability}%`}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            );
                          })}
                        </Card>
                      )}
                    </ListItemButton>
                    <Divider />
                  </React.Fragment>
                );
              })}
            </List>
          ) : (
            <React.Fragment>
              <Divider />
              <Typography sx={{ padding: "10px" }}>
                ログが見つかりませんでした
              </Typography>
              <Divider />
            </React.Fragment>
          )}
          <FormHelperText>
            自分自身のログを直近3件表示しています。各認識ログの4文字目以降の認識結果については「ログ」メニューで確認してください。
          </FormHelperText>
        </Box>
      </Paper>
    </div>
  );
}

export default ContextAI;
