// 定義ファイル

//export const BASE_ADDRESS = "https://localhost:44363/api/";
export const BASE_ADDRESS = 'https://suekichi-ai.ddo.jp/api/api/';
export const PREDICT_ADDRESS = "https://os3-324-51602.vs.sakura.ne.jp/";

export const PAGE_ID = {
  HOME: "Home",
  HIRAGANA_TREE: "Hiragana/RF",
  HIRAGANA_CNN: "Hiragana/CNN",
  NUMBER_TREE: "Number/RF",
  NUMBER_CNN: "Number/CNN",
  SENTENCE_TREE: "Sentence/RF",
  SENTENCE_CNN: "Sentence/CNN",
  LOG_PERSONAL: "Log/Personal",
  LOG_GENERAL: "Log/General",
};

export const PREDICT_TYPE = {
  HIRAGANA_TREE: 0,
  HIRAGANA_CNN: 1,
  NUMBER_TREE: 2,
  NUMBER_CNN: 3,
  SENTENCE_TREE: 4,
  SENTENCE_CNN: 5,
};
