import "./App.css";
import Header from "./PCView/Control/header/header";
import SideMenu from "./PCView/Control/sideMenu/sideMenu";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { isOpenToast, loading, toastState } from "./Util/recoil/atom";
import { AxiosClientProvider } from "./Util/web_api";
import { Backdrop, CircularProgress, Grow, Snackbar } from "@mui/material";
import Home from "./PCView/Component/home/home";
import HiraganaCNN from "./PCView/Component/hiraganaAI/CNN/hiraganaCNN";
import PersonalLog from "./PCView/Component/log/personal/personalLog";
import NumCNN from "./PCView/Component/numAI/CNN/numCNN";
import GeneralLog from "./PCView/Component/log/general/generalog";
import HiraganaTree from "./PCView/Component/hiraganaAI/tree/hiraganaTree";
import NumsCNN from "./PCView/Component/contextAI/CNN/numCNN";

function GrowTransition(props) {
  return <Grow {...props} />;
}

function App(props) {
  const isLoading = useRecoilValue(loading);
  const [_toastState, _setToastState] = useRecoilState(toastState);

  const onCloseSnackBar = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    _setToastState({ isOpen: false, context: _toastState.context });
  };

  return (
    <BrowserRouter>
      <AxiosClientProvider>
        {isLoading && (
          <Backdrop sx={{ color: "#fff", zIndex: 1500 }} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Snackbar
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          open={_toastState.isOpen}
          onClose={onCloseSnackBar}
          message={_toastState.context}
          autoHideDuration={10000}
          TransitionComponent={GrowTransition}
        />
        <div className="App">
          <Header />
          <div className="pcMain" style={{ display: "flex" }}>
            <SideMenu />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/Hiragana/RF" element={<HiraganaTree />} />
              <Route path="/Hiragana/CNN" element={<HiraganaCNN />} />
              <Route path="/Number/CNN" element={<NumCNN />} />
              <Route path="/Sentence/CNN" element={<NumsCNN />} />
              <Route path="/Log/Personal" element={<PersonalLog />} />
              <Route path="/Log/General" element={<GeneralLog />} />
            </Routes>
          </div>
        </div>
      </AxiosClientProvider>
    </BrowserRouter>
  );
}

export default App;
